.terms-container {
  max-width: 800px;
  margin: 0 auto; /* Centra el contenido horizontalmente */
  padding: 80px;
  background-color: #f9f9f9; /* Fondo ligero para destacar el contenido */
  border-radius: 8px; /* Bordes redondeados para un mejor diseño */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidad */
  font-family: Arial, sans-serif;
}

.terms-container h1 {
  text-align: center; /* Centra el título */
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.terms-container ul {
  list-style-type: none; /* Elimina viñetas */
  padding: 0;
}

.terms-container li {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.terms-container li strong {
  color: #000;
}

@media (max-width: 600px) {
  .terms-container {
    padding: 15px; /* Reduce padding en móviles */
    box-shadow: none; /* Elimina sombra en móviles para simplicidad */
  }

  .terms-container h1 {
    font-size: 1.5rem; /* Reduce tamaño de fuente del título en móviles */
  }

  .terms-container li {
    font-size: 0.95rem; /* Ajusta tamaño de texto en móviles */
  }
}
