.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
}

.mainTitle {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #123D4D;
}

.columns {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap; /* Permite que los elementos pasen a la siguiente línea si no hay espacio */
}

.column {
  flex: 1;
  max-width: 300px;
  padding: 10px;
}

.columnTitle {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #123D4D;
}

.columnDescription {
  font-size: 1rem;
  color: #555;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
  .columns {
    flex-direction: column; /* Apila las columnas en una sola fila vertical */
    align-items: center;   /* Centra los elementos */
  }

  .column {
    max-width: 90%; /* Ancho dinámico para adaptarse mejor a pantallas pequeñas */
    margin-bottom: 20px; /* Espaciado entre columnas apiladas */
  }
}
