.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:  clamp(20px, 5vw, 40px) 80px;
  background: linear-gradient(135deg, #123D4D, #1D6D7E, #56B0B0);

  .header-content {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
  }

  .text-content {
    flex: 1;
    max-width: 600px;

    h1 {
      font-size: 2.5rem;
      color: #f9f9f9;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.5;
      margin-bottom: 30px;
      color: #f9f9f9;
    }

    .download-section {
      display: flex;
      gap: 20px;

      .google-play-btn {
        display: inline-block;

        img {
          max-width: 150px;
          transition: transform 0.3s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .qr-code {
        max-width: 150px;
        /* border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
      }
    }
  }

  .image-content {
    flex: 1;
    max-width: 600px;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      /* border-radius: 15px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
    }
  }
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    text-align: center;
  }

  .text-content {
    max-width: 100%;

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }

    .download-section {
      flex-direction: column;
      gap: 10px;

      .google-play-btn img {
        max-width: 200px;
      }

      .qr-code {
        max-width: 200px;
      }
    }
  }

  .image-content {
    max-width: 100%;
    margin-top: 20px;
  }
}

.download-section {
  display: flex;
  flex-direction: column;
  /* Apila los elementos verticalmente */
  align-items: flex-start;
  /* Alinea los elementos al inicio horizontalmente */
  gap: 15px;
  /* Espaciado entre el QR y el botón */

  .qr-code {
    max-width: 120px;
    border: 2px solid #ddd;
    /* border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }

  .google-play-btn img {
    max-width: 180px;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
      /* Efecto al pasar el mouse */
    }
  }
}