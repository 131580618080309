.features {
  text-align: center;
  padding: 40px 20px;
  background-color: #89E2C4;
}

.featureList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.featureItem {
  width: 300px;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.featureItem img {
  width: 60px; /* Ajusta según el tamaño que quieras */
  height: 60px;
  margin-bottom: 15px; /* Espacio entre la imagen y el título */
}

.featureItem h3 {
  font-size: 1.3rem;
  color: #333;
}

.featureItem p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}
