.detail-content {
  max-width: 800px;
  margin: 20px auto;
  /* Centra el contenido horizontalmente */
  padding: 80px;
  background-color: #f9f9f9;
  /* Fondo ligero para destacar el contenido */
  border-radius: 8px;
  /* Bordes redondeados para un mejor diseño */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Sombra para dar profundidad */
  font-family: Arial, sans-serif;
}

.detail-content h1 {
  text-align: center;
  /* Centra el título */
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.detail-content ul {
  list-style-type: none;
  /* Elimina viñetas */
  padding: 0;
}

.detail-content li {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.detail-content li strong {
  color: #000;
}

@media (max-width: 600px) {
  .detail-content {
    padding: 15px;
    /* Reduce padding en móviles */
    box-shadow: none;
    /* Elimina sombra en móviles para simplicidad */
  }

  .detail-content h1 {
    font-size: 1.5rem;
    /* Reduce tamaño de fuente del título en móviles */
  }

  .detail-content li {
    font-size: 0.95rem;
    /* Ajusta tamaño de texto en móviles */
  }
}


footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  /* Color de fondo */
  color: white;
  /* Color del texto */
  padding: 20px 0;
  /* Espaciado interno */
  position: relative;
  bottom: 0;
  width: 100%;
}

footer a {
  margin: 0 15px;
  /* Espaciado entre enlaces */
  color: white;
  /* Color de los enlaces */
  text-decoration: none;
  /* Sin subrayado */
}

footer a:hover {
  text-decoration: underline;
  /* Subrayado al pasar el cursor */
}