.contactUs {
  padding: 50px 20px;
  text-align: center;
  background-color: #f8f8f8;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputField, .textareaField {
  width: 80%;
  max-width: 500px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.textareaField {
  height: 100px;
}

.submitButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #282c34;
  color: white;
  cursor: pointer;
}
