.social-links {
  background-color: #123D4D;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
    color: #f9f9f9;
    text-align: center;
  }

  p {
    margin-bottom: 30px;
    color: #f9f9f9;
    text-align: center;
    font-size: 24px;
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 10px 20px;
      text-decoration: none;
      color: #333;
      font-size: 16px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .linkedin {
      border-color: #0077b5;
    }

    .medium {
      border-color: #00ab6c;
    }

    .github {
      border-color: #333;
    }
  }
}

.profile-pic { width: 150px; height: 150px; border-radius: 50%; display: block; margin: 0 auto 20px; }