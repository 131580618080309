.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #89E2C4; /* Color de fondo del navbar */
  padding: 10px 0; /* Espaciado interno */
  width: 100%;
}

.navbarLogo {
  text-align: center;
}

.logo {
  height: 80px; /* Ajusta el tamaño del logo */
  cursor: pointer;
}

.navbar a {
  color: black; /* Color del enlace */
  text-decoration: none;
}
